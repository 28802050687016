import envVars from 'env/config';

const { routes } = envVars;
const endpoints = {
  fetchSites: `${routes.sitesService}/sites/`,
  fetchSitesFilters: `${routes.sitesService}/sites/filters`,
  fetchSiteBusBand: `${routes.sitesService}/sites/{siteId}/busband`,
  patchSiteBusBandPoints: `${routes.sitesService}/sites/{siteId}/config`,
  postBusBandStatus: `${routes.sitesService}/sites/{siteId}/busband/status`,
  fetchSiteDetail: `${routes.sitesService}/sites/{siteId}`,
  fetchSiteEvent: `${routes.sitesService}/sites/{siteId}/events`,
  fetchSitesIcianUsers: `${routes.sitesService}/sites/assigned-user`,
  fetchNewUsernamePassword: `${routes.sitesService}/sites/{siteId}/token`,
  fetchCACertificate: `${routes.sitesService}/sites/{siteId}/ca-certificate`,
  fetchSiteTelemetry: `${routes.sitesService}/sites/{siteId}/overview`,
  fetchSiteAuditLog: `${routes.sitesService}/sites/{siteId}/audit-log`,
  fetchSitesPermissions: `${routes.sitesService}/sites/{siteId}/permissions`,
  deleteSitesPermissions: `${routes.sitesService}/sites/{siteId}/permissions/{userId}`,
  updateTimeZone: `${routes.sitesService}/sites/{siteId}/time-zone`,
  fetchTimeZones: `${routes.sitesService}/sites/time-zones?country={country}`,
  fetchBillingInfo: `${routes.sitesService}/sites/site/{siteId}/simpleBilling`,
  fetchTariffStructure: `${routes.sitesService}/sites/{siteId}/tariff-structure`,
  fetchSitesCompanies: `${routes.sitesService}/sites/companies`,
  updateTariffStructure: `${routes.sitesService}/sites/{siteId}/tariff-structure/{tariffId}`,
  setCradlePointRouterId: `${routes.sitesService}/sites/{siteId}/router-id`,
  setEdgeDataTimeout: `${routes.sitesService}/sites/{siteId}/edge-data-timeout`,
  fetchNotificationsSettings: `${routes.usersService}/users/settings`,
  updateNotificationsSettings: `${routes.usersService}/users/settings`,
  fetchUserPolicy: `${routes.usersService}/users/policy`,
  fetchOktaUsers: `${routes.usersService}/users/okta/users`,
  fetchBroadcastUsers: `${routes.usersService}/users/allusers`,
  updateBroadcastMessage: `${routes.usersService}/users/broadcastmessage`,
  updateUserOktaProfile: `${routes.usersService}/users/info`,
  userSessionEvents: `${routes.usersService}/users/events/{event}`,
  fetchUserRoleConfig: `${routes.usersService}/users/role`,
  fetchUserAppConfig: `${routes.usersService}/users/appconfig`,
  fetchNotification: `${routes.notificationsService}/notifications/?page=0&size=500`,
  patchNotification: `${routes.notificationsService}/notifications/dismiss`,
  fetchEnergyCharts: `${routes.chartsService}/charts/energy-productions`,
  fetchESSCharts: `${routes.chartsService}/charts/ess-states`,
  fetchFacilityCharts: `${routes.chartsService}/charts/facility-usage-productions`,
  fetchVehicleAndFleetCharts: `${routes.chartsService}/charts/vehicle-and-fleets`,
  fetchShareReports: `${routes.chartsService}/charts/share-reports`,
  fetchMonthlyReports: `${routes.chartsService}/charts/monthly-report`,
  fetchReportsExcel: `${routes.chartsService}/charts/monthly-report/csv`,
  fetchPowerBIToken: `${routes.chartsService}/charts/pbi-config`,
  fetchDevicesBySiteId: `${routes.devicesService}/devices?bySiteId={siteId}`,
  fetchDeviceStatus: `${routes.devicesService}/devices/{deviceId}/status`,
  fetchDeviceTelemetry: `${routes.devicesService}/devices/{deviceId}/telemetry`,
  fetchDeviceConfig: `${routes.devicesService}/devices/{deviceId}/config`,
  fetchDeviceExcelExport: `${routes.devicesService}/devices/{deviceId}/events/export`,
  fetchDeviceEvents: `${routes.devicesService}/devices/{deviceId}/events?page={page}&size={size}`,
  fetchTelemetryBySiteIdAndType: `${routes.devicesService}/devices/{siteId}/telemetry/{deviceType}`,
  fetchResourcesFolders: `${routes.usersService}/training-materials/folders`,
  fetchResourcesFolderItems: `${routes.usersService}/training-materials/items/{folderId}`,
  fetchResourcesItemURL: `${routes.usersService}/training-materials/items/{folderId}/download/{itemId}`,
  fetchSiteNotifications: `${routes.usersService}/users/site-notifications-settings`,
  fetchSiteConfiguration: `${routes.sitesService}/sites/siteconfig/{siteId}`,
  updateSiteConfiguration: `${routes.sitesService}/sites/siteconfig/{siteId}`,
  fetchWorkOrdersBySiteId: `${routes.sitesService}/sites/workorders/{siteId}`,
  fetchWorkOrdersByUser: `${routes.sitesService}/sites/overviewworkorders`,
  updateWorkOrders: `${routes.sitesService}/sites/workorders`,
  dismissBroadcastMessage: `${routes.usersService}/users/dismissbroadcastmessage`,
  uploadNewDocument: `${routes.usersService}/document-manager/category/{category}`,
  updateDocumentFile: `${routes.usersService}/document-manager/category/{category}/file/{file-name}`,
  getDocumentFile: `${routes.usersService}/document-manager/category/{category}/file/{filename}`,
  deleteDocumentFile: `${routes.usersService}/document-manager/category/{category}/file/{filename}`,
  getDocumentsBySiteType: `${routes.usersService}/document-manager/site-type-name/{siteTypeName}`,
  getAllDocumentMetadata: `${routes.usersService}/document-manager/file-metadata`,
};

export default endpoints;
