/**
 * User constants
 */
export const STORE_USER = '[User] STORE_USER';
export const FETCH_USER_POLICY_START = '[User] FETCH_USER_POLICY_START';
export const FETCH_USER_POLICY = '[User] FETCH_USER_POLICY';
export const SET_USER_POLICY = '[User] SET_USER_POLICY';
export const FETCH_USER_POLICY_FAIL = '[User] FETCH_USER_POLICY_FAIL';
export const UPDATE_BROADCAST_MESSAGE_START = '[User] UPDATE_BROADCAST_MESSAGE_START';
export const UPDATE_BROADCAST_MESSAGE_SUCCESS = '[User] UPDATE_BROADCAST_MESSAGE_SUCCESS';
export const UPDATE_BROADCAST_MESSAGE_FAIL = '[User] UPDATE_BROADCAST_MESSAGE_FAIL';
export const UPDATE_TERMS_AND_CONDITIONS = '[User] UPDATE_TERMS_AND_CONDITIONS';
export const UPDATE_TERMS_AND_CONDITIONS_FAIL = '[User] UPDATE_TERMS_AND_CONDITIONS_FAIL';
export const UPDATE_USER_PROFILE_OKTA_FAIL = '[User] UPDATE_USER_PROFILE_OKTA_FAIL';
export const UPDATE_USER_PROFILE_OKTA = '[User] UPDATE_USER_PROFILE_OKTA';
export const UPDATE_USER_PASSWORD_OKTA = '[User] UPDATE_USER_PASSWORD_OKTA';
export const CLEAN_OKTA_MESSAGES_PASSWORD = '[User] CLEAN_OKTA_MESSAGES_PASSWORD';

export const DISMISS_BROADCAST_MESSAGE_FAIL = '[User] DISMISS_BROADCAST_MESSAGE_FAIL';
export const DISMISS_BROADCAST_MESSAGE_SUCCESS = '[User] DISMISS_BROADCAST_MESSAGE_SUCCESS';

export const FETCH_BROADCAST_USERS_START = 'FETCH_BROADCAST_USERS_START';
export const FETCH_BROADCAST_USERS_SUCCESS = 'FETCH_BROADCAST_USERS_SUCCESS';
export const FETCH_BROADCAST_USERS_FAIL = 'FETCH_BROADCAST_USERS_FAIL';

export const SET_USER_ROLE_CONFIG = '[User] SET_USER_ROLE_CONFIG';
export const SET_USER_ROLE_CONFIG_FAIL = '[User] SET_USER_ROLE_CONFIG_FAIL';
export const SET_USER_APP_CONFIG = '[User] SET_USER_APP_CONFIG';
export const SET_USER_PERMISSIONS = '[User] SET_USER_PERMISSIONS';

// Document Management Types
export const UPLOAD_NEW_DOCUMENT_START = 'UPLOAD_NEW_DOCUMENT_START';
export const UPLOAD_NEW_DOCUMENT_SUCCESS = 'UPLOAD_NEW_DOCUMENT_SUCCESS';
export const UPLOAD_NEW_DOCUMENT_FAIL = 'UPLOAD_NEW_DOCUMENT_FAIL';

export const UPDATE_DOCUMENT_FILE_START = 'UPDATE_DOCUMENT_FILE_START';
export const UPDATE_DOCUMENT_FILE_SUCCESS = 'UPDATE_DOCUMENT_FILE_SUCCESS';
export const UPDATE_DOCUMENT_FILE_FAIL = 'UPDATE_DOCUMENT_FILE_FAIL';

export const GET_DOCUMENT_FILE_START = 'GET_DOCUMENT_FILE_START';
export const GET_DOCUMENT_FILE_SUCCESS = 'GET_DOCUMENT_FILE_SUCCESS';
export const GET_DOCUMENT_FILE_FAIL = 'GET_DOCUMENT_FILE_FAIL';

export const DELETE_DOCUMENT_FILE_START = 'DELETE_DOCUMENT_FILE_START';
export const DELETE_DOCUMENT_FILE_SUCCESS = 'DELETE_DOCUMENT_FILE_SUCCESS';
export const DELETE_DOCUMENT_FILE_FAIL = 'DELETE_DOCUMENT_FILE_FAIL';

export const GET_DOCUMENTS_BY_SITE_TYPE_START = 'GET_DOCUMENTS_BY_SITE_TYPE_START';
export const GET_DOCUMENTS_BY_SITE_TYPE_SUCCESS = 'GET_DOCUMENTS_BY_SITE_TYPE_SUCCESS';
export const GET_DOCUMENTS_BY_SITE_TYPE_FAIL = 'GET_DOCUMENTS_BY_SITE_TYPE_FAIL';

export const GET_ALL_DOCUMENT_METADATA_START = 'GET_ALL_DOCUMENT_METADATA_START';
export const GET_ALL_DOCUMENT_METADATA_SUCCESS = 'GET_ALL_DOCUMENT_METADATA_SUCCESS';
export const GET_ALL_DOCUMENT_METADATA_FAIL = 'GET_ALL_DOCUMENT_METADATA_FAIL';
